<template>
  <div>
    <a-modal width="500px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="cancel">
      <a-form ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="用户名称：" name="username">
          <a-input v-model:value="formState.username" style="width: 370px;"/>
        </a-form-item>
        <a-form-item label="用户密码：" name="password">
           <a-input-password v-model:value="formState.password" style="width: 370px;"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { user_post} from '@/api/system'
const form = {
  username: '',
  password: '',
  isAdmin:true
}
 var newPswdValidator = async (rule, value) => {
      const passwordreg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}/
      if (!value) {
        return Promise.reject('请输入密码');
      } else {
        if (!passwordreg.test(value)) {
          // return true
          return Promise.reject('密码为8-20位,且同时包含数字、大写字母、小写字母、特殊符号');
        } else {
          return true
        }
      }
    };
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      visible: false,
      formState: {
        ...form
      },
      rules: {
        username: [{ required: true, message: '不能为空', trigger: 'change' }],
        password: [{ validator: newPswdValidator, required: true,}],
      },
    };
  },
  created() {
  },
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.visible = false;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
          user_post(this.formState).then(res => {
            this.visible = false
            this.confirmLoading = false
            this.cancel()
            this.$parent.handleSearch();
          })
      });
    },
    showModal(val) {
      this.visible = true,
      this.formState= {
        ...form
      }
    }
  },
});
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    