import { createFromIconfontCN } from "@ant-design/icons-vue";
import { defineComponent, VNode, PropType } from "vue";
// 用法 <myIcon name="export" />
const MyIcon = createFromIconfontCN({
  scriptUrl:
    "https://lf1-cdn-tos.bytegoofy.com/obj/iconpark/svg_18657_24.ba955e2b9e995677149da8f2250a1cc9.js", // 在 iconfont.cn 上生成
});

type IconName = string;

interface IconProps {
  name: IconName;
}

export default defineComponent({
  props: {
    name: String as PropType<IconName>,
  },
  setup(props) {
    return (): VNode => <MyIcon type={(props as IconProps).name} />;
  },
});
