<template>
  <div>
    <a-modal width="600px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <div style="">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <a-form-item label="角色：" name="type">
            <a-checkbox-group v-model:value="formState.value" :options="plainOptions" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { userRole, rolePage, userId } from '@/api/system'
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      userId: '',
      visible: false,
      plainOptions: [],
      formState: {
        value: []
      },
      rules: {
        difficultyLevel: [
          {
            required: true,
            message: '不能为空',
            transform: (value) => {
              if (value) {
                return value.toString()
              }
              return value
            }
          }
        ]
      },
      editor: false
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.formState = {
        value: []
      }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      userRole({ roleIds: this.formState.value, userId: this.userId }).then(
        (res) => {
          this.visible = false
          this.$parent.handleSearch()
        }
      )
    },
    showModal(val) {
      this.visible = true
      this.userId = val
      const params_api = {
        currentPage: 1,
        pageSize: 1000
      }
      rolePage(params_api).then((res) => {
        this.plainOptions = []
        res.data.forEach((res) => {
          this.plainOptions.push({ label: res.roleName, value: res.roleId })
        })
      })
      userId(val).then((res) => {
        this.formState.value = res.data.roleIds
      })
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    